import './index.css';
import Nav from './components/Nav';
import About from './pages/About';
import Home from './pages/Home';
import Product from './pages/Product';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';

function App() {
	return (
		<div className='max-h-screen overflow-scroll'>
			<Nav />

			<section id='home'>
				<div className='spacing-nav'></div>
				<Home />
			</section>
			<section id='product'>
				<div className='spacing-nav'></div>
				<Product />
			</section>
			<section id='about'>
				<div className='spacing-nav'></div>
				<About />
			</section>
			<section id='contact'>
				<div className='spacing-nav'></div>
				<ContactUs />
			</section>
			<Footer />
		</div>
	);
}

export default App;
