import { Button } from '@material-tailwind/react';

export default function Home() {
	return (
		<div className='w-screen'>
			<div className='text-center md:px-[20%] md:py-10 px-5 py-3 flex flex-col  gap-5'>
				<div className='flex flex-col text-[#212121] font-bold text-[30px] md:text-[40px] lg:text-[52px] leading-[40px] md:leading-[50px] lg:leading-[60px]'>
					<span className=''>Shock and Awe</span>
					<span>Elevate Your Brand!</span>
				</div>
				<span className='text-[#0F1722] font-normal text-[14px] md:text-[18px]'>
					At Rentgers, we’re specialists in captivating audiences. Enhance your brand’s presence, entice people to interact and
					create an impression for any event. We’re not just about renting equipment. We’re your portal to the FUTURE of event
					marketing.
				</span>
				<div className='my-5'>
					<a href='https://wa.me/60126136161'>
						<Button
							variant='filled'
							size='sm'
							className='capitalize text-white text-[12px] md:text-[16px] rounded-full md:px-6 md:py-5 p-3 bg-[#F8BB00]'>
							<span>Get in touch with us</span>
						</Button>
					</a>
				</div>
			</div>
			<img className='block md:hidden object-cover object-center mt-5' src='/images/mobileHome.svg' alt='homemobile' />
			<img className='hidden md:block object-cover object-center ' src='/images/home.svg' alt='home' />
		</div>
	);
}
