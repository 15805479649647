import { Navbar, MobileNav, Button, IconButton, Typography, Collapse } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import { HiOutlineXMark } from 'react-icons/hi2';
import { HiMenu } from 'react-icons/hi';
function Nav() {
	const [openNav, setOpenNav] = useState(false);
	useEffect(() => {
		window.addEventListener('resize', () => window.innerWidth >= 960 && setOpenNav(false));
	}, []);
	const navText = 'text-[#212121] font-semibold text-[14px]';
	const navList = (
		<ul className='mt-2 mb-4 flex flex-col gap-2 md:mb-0 md:mt-0  md:flex-row md:items-center md:gap-6'>
			<Typography as='li' variant='small' className={navText}>
				<a href='#home' className='flex items-center '>
					Home
				</a>
			</Typography>
			<Typography as='li' variant='small' className={navText}>
				<a href='#product' className='flex items-center'>
					Product
				</a>
			</Typography>
			<Typography as='li' variant='small' className={navText}>
				<a href='#about' className='flex items-center'>
					About
				</a>
			</Typography>
			<Typography as='li' variant='small' className={navText}>
				<a href='#contact' className='flex items-center'>
					Contact Us
				</a>
			</Typography>
		</ul>
	);
	return (
		<Navbar className='sticky top-0 z-10 h-fit max-w-full px-4 md:px-8 rounded-none  shadow-none '>
			<div className='flex items-center justify-between text-blue-gray-900 md:px-[10%]  lg:px-[20%] '>
				<img className=' rounded-lg object-cover object-center w-[30%]  md:w-[15%]' src='/images/logo.png' alt='logo' />
				<div className='flex items-center gap-4'>
					<div className=' hidden md:block'>{navList}</div>

					<IconButton
						variant='text'
						className='ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden'
						ripple={false}
						onClick={() => setOpenNav(!openNav)}>
						{openNav ? <HiOutlineXMark color='black' size={25} /> : <HiMenu color='black' size={25} />}
					</IconButton>
				</div>
				<div className=' items-center gap-x-1 hidden md:flex'>
					<a href='https://wa.me/60126136161'>
						<Button
							variant='filled'
							size='sm'
							className='hidden md:inline-block text-white text-[10px] lg:text-[12px] py-3 px-5 rounded-full  bg-[#F8BB00]'>
							<span>Get started</span>
						</Button>
					</a>
				</div>
			</div>

			<Collapse open={openNav} className='px-5'>
				{navList}
			</Collapse>
		</Navbar>
	);
}
export default Nav;
