import { BsFacebook, BsLinkedin, BsTwitterX, BsInstagram, BsYoutube, BsWhatsapp } from 'react-icons/bs';

function Footer() {
	return (
		<div>
			<footer className='bg-white '>
				<div className='md:px-[20%] px-5  py-4 md:py-8 '>
					<div className='flex flex-col-reverse md:flex-row  md:items-center md:justify-between '>
						<div className='flex-1 flex flex-col gap-5 md:gap-16'>
							<div>
								<a href='#home' className='flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse'>
									<img className=' object-cover object-center w-[40%] md:w-[30%]' src='/images/logo.svg' alt='logo' />
								</a>
								<span className='text-[14px] font-light text-[#636670]'>Endless Possibilites with Rentgers</span>
							</div>
							<div>
								<span className='text-[14px] font-semibold text-[#212121]'>Follow Us</span>
								<div className='flex flex-row my-4 gap-5'>
									<a href='https://www.facebook.com/profile.php?id=61552470194739'>
										<BsFacebook size={20} />
									</a>
									<a href='https://www.instagram.com/rentgers/'>
										<BsInstagram size={20} />
									</a>

									<a href='https://wa.me/60126136161'>
										<BsWhatsapp size={20} />
									</a>
									<a href='https://www.xiaohongshu.com/user/profile/640c9a2c00000000290136a8?xhsshare=CopyLink&appuid=640c9a2c00000000290136a8&apptime=1698224854'>
										<img
											className=' object-cover object-center w-6  -ml-1'
											src='/images/xiaohongshulogo.svg'
											alt='logo '
										/>
									</a>
								</div>
							</div>
						</div>
						<div className=' flex-1 flex md:justify-center '>
							<ul className='flex flex-col gap-4  mb-4 text-sm font-normal text-[#636670] md:mb-0'>
								<p className='text-lg font-semibold text-black mb-4'>Products</p>
								<li>
									<a href='#home' className='hover:underline'>
										Home
									</a>
								</li>
								<li>
									<a href='#product' className='hover:underline'>
										Product
									</a>
								</li>
								<li>
									<a href='#about' className='hover:underline'>
										About
									</a>
								</li>
								<li>
									<a href='#contact' className='hover:underline'>
										Contact Us
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<hr className='mb-4 md:mt-8 border-gray-100 md:mx-auto ' />
				<span className='md:px-[20%] px-10 pb-4 text-center md:text-left block text-[12px] text-gray-500  dark:text-gray-400'>
					© 2024{' '}
					<a href='/' className='hover:underline mr-1'>
						All right reserved Rentgers.
					</a>
					Powered by Ocunapse.
				</span>
			</footer>
		</div>
	);
}
export default Footer;
