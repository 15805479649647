import { Button } from '@material-tailwind/react';

function About() {
	return (
		<div className='bg-[#33476C]'>
			<div className='lg:px-[20%] px-10 sm:px-20 pt-12 md:pt-0'>
				<div className='flex md:flex-row flex-col-reverse items-start justify-center  gap-20'>
					<div className='md:flex-1 '>
						<img className='object-cover object-center -ml-14 md:ml-0 ' src='/images/about.png' alt='about' />
					</div>
					<div className='flex flex-col text-left flex-1 self-center gap-5 '>
						<span className='text-[16px] xl:text-[18px] text-white font-medium'>
							"Our solutions make marketing more engaging, interactive, and fun, creating memorable experiences that make your
							brand the talk of the event. Whether it's a product launch, brand activation, or promotional event, we provide
							the tools to bring your vision to life"
						</span>
						<div className='mt-5'>
							<a href='https://wa.me/60126136161'>
								<Button variant='filled' size='sm' className=' text-white text-[14px] rounded-full p-4 bg-[#F8BB00]'>
									<span>Get started</span>
								</Button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
