import { Button } from '@material-tailwind/react';

function ContactUs() {
	return (
		<div className='bg-[#F8BB00] px-10 lg:px-[20%] pb-20 md:gap-5  md:py-20 flex flex-col-reverse  md:flex-row items-center justify-center'>
			<div className='flex flex-col lg:flex-1'>
				<span className='text-[32px] font-bold'>Get Started Today.</span>
				<span className='text-white text-[32px] font-bold -mt-2'>Contact Us.</span>

				<span className='text-white text-[16px] mt-2'>
					Our dedicated team is here to assist you in any way we can, we're eager to hear from you.
				</span>
				<div className='my-10'>
					<a href='https://wa.me/60126136161'>
						<Button variant='filled' size='sm' className=' text-white text-[14px] rounded-full p-5 bg-black'>
							<span>Whatsapp Now</span>
						</Button>
					</a>
				</div>
				<div className='flex flex-row items-center  gap-4'>
					<img className='object-cover object-center  ' src='/images/checkIcon.svg' alt='check' />
					<span className='text-white text-[14px] font-normal'>Easiest way to read news and RSS Feeds.</span>
				</div>
				<div className='flex flex-row items-center mt-5 gap-4'>
					<img className='object-cover object-center  ' src='/images/checkIcon.svg' alt='check' />
					<span className='text-white text-[14px] font-normal'>Works with all mail providers, including Google and Yahoo.</span>
				</div>
				<div className='flex flex-row items-center mt-5 gap-4'>
					<img className='object-cover object-center  ' src='/images/checkIcon.svg' alt='check' />
					<span className='text-white text-[14px] font-normal'>Over 2000, highly recommended customer reviews.</span>
				</div>
			</div>
			<div className='mt-14 md:mt-0 mb-10 md:mb-0 lg:flex-1'>
				<img
					className=' object-cover object-center md:float-end lg:float-none md:m-0 m-auto  lg:m-auto w-[100%]  lg:w-[50%]'
					src='/images/phone.svg'
					alt='phone'
				/>
			</div>
		</div>
	);
}

export default ContactUs;
