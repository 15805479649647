import { Button, Carousel } from '@material-tailwind/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
function Product() {
	const products = [
		{
			images: [
				'/rentgers/1.png',
				'/rentgers/2.png',
				'/rentgers/3.png',
				'/rentgers/4.png',
				'/rentgers/5.png',
				'/rentgers/6.png',
				'/rentgers/7.png',
			],
			title: 'Eye Catching',
			subTitle: 'Event Platforms',
			content: `We hold an extensive inventory with the latest in marketing technology. From interactive displays, augmented reality setups and mobile kiosks like the Piaggio for pop-up stores, we’ve got it all. Get your audience excited to interact with your own unique vision for your event.
        `,
			buttonUrl: '',
			buttonTitle: 'Get Started',
		},

		{
			images: ['/hologram/1.png', '/hologram/2.png', '/hologram/3.png', '/hologram/4.png', '/hologram/5.png', '/hologram/6.png'],
			title: 'Teleport to',
			subTitle: 'A Holographic Future',
			content: `Our LuxScreen, QLED Screen display & 3D hologram boxes can be loaded onto mobile vehicles, or used in your event spaces to bring the future of interactive engagements to life with our technology to create your own avatars in real time; or pre-set an avatar template for promotional use.  
        `,
			buttonUrl: '',
			buttonTitle: 'Get Started',
		},
		{
			images: ['/mobile/1.png', '/mobile/2.png', '/mobile/3.png'],
			title: 'Pop-Up Stores',
			subTitle: 'Built to Benefit',
			content: `The designs of our mobile shops are made to be customisable in order to fit anywhere; indoors, outdoors, at your event, on the sidewalks. Take advantage of the flexibility to appear anywhere, at events, conventions, concerts, etc for your brand and products.
        `,
			buttonUrl: '',
			buttonTitle: 'Get Started',
		},
		{
			images: [
				'/qled/1.png',
				'/qled/2.png',
				'/qled/3.png',
				'/qled/4.png',
				'/qled/5.png',
				'/qled/6.png',
				'/qled/7.png',
				'/qled/8.png',
			],
			title: 'QLED Screen Display',
			subTitle: 'Captivate Any Space',
			content:
				'Immerse Your Audience: QLED Displays for Every Space! Perfect for exhibition halls, banks, museums, sales departments, and shopping malls, our displays captivate with stunning visuals. Whether showcasing art, promoting products, or providing information, our QLED screens ensure your message stands out. With sleek design and cutting-edge technology, create engaging experiences anywhere',
			buttonUrl: '',
			buttonTitle: 'Get Started',
		},
		{
			images: [
				'/estamp/1.png',
				'/estamp/2.png',
				'/estamp/3.png',
				'/estamp/4.png',
				'/estamp/5.png',
				'/estamp/6.png',
				'/estamp/7.png',
				'/estamp/8.png',
			],
			title: 'The Ultimate ',
			subTitle: 'SaaS e-Stamping',
			content: `Beyond just being a stamp, it’s a total solution to your data management, engagement and future planning references. It’s the perfect tool for chain stores engagements and inventory stock taking. Minimise your manpower needs and have complete control in your next campaign or event.
        `,
			buttonUrl: '',
			buttonTitle: 'Get Started',
		},
	];
	function SampleNextArrow({ ...props }) {
		const { className, style, onClick } = props;
		return <BsChevronRight color='black' className={className} onClick={onClick} />;
	}
	function SamplePrevArrow({ ...props }) {
		const { className, style, onClick } = props;
		return <BsChevronLeft color='black' className={className} onClick={onClick} />;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className='md:py-14 pb-5 w-full'>
			<div className='text-center border-b border-b-[#2121211A] pb-5'>
				<span className='text-[#212121] font-bold text-[42px] '>Our Products</span>
			</div>
			<div className='md:px-[15%] px-10'>
				{products.map((product, index) => (
					<div key={index} className='lg:flex lg:odd:flex-row-reverse items-center justify-center md:my-20 my-10  gap-12'>
						<div className='flex flex-col text-left md:flex-1 p-2 gap-5 mb-10'>
							<span className='text-[38px] text-[#F8BB00] font-semibold flex-col flex'>
								{product.title} <span className=' text-[#212121]'>{product.subTitle}</span>
							</span>

							<span className='text-[14px] text-[#636670] font-light'>{product.content}</span>
							<div className='mt-5'>
								<a href='https://wa.me/60126136161'>
									<Button variant='filled' size='sm' className=' text-white text-[12px] rounded-full p-3 bg-[#F8BB00]'>
										<span>{product.buttonTitle}</span>
									</Button>
								</a>
							</div>
						</div>
						<div className='md:flex-1 mx-auto w-[100%]  lg:w-[30%]  '>
							<Slider {...settings}>
								{product.images.length > 0 &&
									product.images.map((i, index) => (
										<img key={index} src={i} alt='carousel' className='h-full w-full object-cover' />
									))}
							</Slider>
							{/* <Carousel carouselImages={carouselImages} /> */}
						</div>
					</div>
				))}
			</div>
			<div className='text-center flex flex-col '>
				<span className='text-[#212121] font-bold text-[38px] '>Get In Touch.</span>
				<span className='text-[#F8BB00] font-bold text-[38px] '>Send Us a Message.</span>
			</div>
		</div>
	);
}

export default Product;
